.dashboard-layout{
    .dashboard-container{

    }
    main.dashboard-main{
        padding: 1rem 1rem;
        flex-grow: 1;
        background-color: #f9f9f9;
    }
    .view-cards {
        margin-top: 1rem;
        background-color: #fff;
        padding: 1rem;
        border-radius: 8px;
        margin-bottom: 1.5rem;
    }
}

section.note-section{
    .card{
        height: 6.5rem;
        overflow: hidden;
        .card-body{
            overflow-y: auto;
        }
    }
}