@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

table.MuiTable-root{
    thead{
        tr{
          background: #239ADC;
            th{
                &.MuiTableCell-alignLeft {
                  padding: 10px;
                  color: #fff;
                  font-family: "Montserrat", sans-serif !important;
                }
                .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
                  color: #fff;
                }
                .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
                  opacity: 1;
                }
            }
        }
    }
    tbody {
      tr {
        .css-1ex1afd-MuiTableCell-root {
          padding: 8px 10px;
          font-family: "Montserrat", sans-serif !important;
        }
      }
    }
}

.consultation-modal {
  audio {
    width: 100%;
  }

}

.view-consultation-modal {
  audio {
    width: 100%;
  }

}

.ml-1 {
  margin-left: 1rem;
}

.modal-dialog {
  .modal-content {
    .modal-header {
      background: #239ADC;
      h5 {
        color: #fff;
      }
      .btn-close {
        border: none;
        filter: brightness(1) invert(1);
        opacity: 1;
      }
    }
  }
}

.css-pdct74-MuiTablePagination-selectLabel {
  font-family: "Montserrat", sans-serif !important;
}

.table-head-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;

  h2 {
    font-size: 22px;
    margin: 10px 0px;
  }


}

/***** Header CSS ******/
body {
    font-family: 'Montserrat', sans-serif;
}

.btn-primary {
  background: #239ADC;
  border-color: #239ADC;
}

.text-primary {
  color: #239ADC;
}

.logo-blue {
  img {
    filter: invert(1);
    /* or to blue */
    filter: invert(0.5) sepia(2) saturate(40) hue-rotate(175deg);
  }
}

.login-page {
  position: relative;
  background: #dcedfe40;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;

  label {
    font-size: 14px;
  }

  .left-img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  
  .login-card {
    // background: #fff;
    border-radius: 5px;
    /* box-shadow: 1px 1px 8px #ddd; */
    padding: 15px;
    margin-right: 20px;
    z-index: 9;
    position: relative;

    img {
      width: 120px;
      margin-bottom: 15px;
    }

    h2 {
      font-weight: 600;
      color: #239ADC;
    }
  }
}
.register-page {
  position: relative;
  background: #dcedfe40;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;

  label {
    font-size: 14px;
  }

  .left-img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .register-card {
    // background: #fff;
    border-radius: 5px;
    // box-shadow: 1px 1px 8px #ddd;
    padding: 15px;
    margin-right: 20px;
    z-index: 9;
    position: relative;

    img {
      width: 120px;
      margin-bottom: 15px;
    }

    h2 {
      font-weight: 600;
      color: #239ADC;
    }
  }
}

.loader-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .logo {
    width: 200px;
    margin-bottom: 25px;
  }
}

@media (max-width:767px) {
  .hidden-xs {
    display: none;
  }
  .login-page {
    .login-card {
      margin-right: 0px;
    }
  }
  .register-page {
    .register-card {
      margin-right: 0;
    }
  }
}

.recording-type {
  h4 {
    text-align: center;
    background: #239adc;
    color: #fff;
    padding: 5px;
    font-size: 18px;
    font-weight: 600;
    margin: -16px -16px 15px;
    border-radius: 5px 5px 0px 0px;
  }
  .time-cont {
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      text-align: center;
      margin: 12px 10px;
      font-size: 60px;
    }
  }
  .record-type-icon {
    text-align: center;
    button {
      width: 100%;
      svg {
        width: 1em;
        height: 1em;
      }
    }
  }
  .action-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
    }

    button {
      margin-top: 10px;
    }
  }
}
.consult-desc {
  h4 {
    background: #239adc;
    color: #fff;
    padding: 5px;
    font-size: 18px;
    font-weight: 600;
    margin: -16px -16px 15px;
    border-radius: 5px 5px 0px 0px;
  }
  .consult-desc-cont {
    p, textarea {
      min-height: 100px;
      max-height: 100px;
      margin-bottom: 10px;
      overflow: auto;
      overflow-x: hidden;
      border: 0;
      resize: none;
    }
  }
  .new-visit-button{
    position: absolute;
    bottom: -60px;
    right: 0;
    z-index: 10;
    @media only screen and (max-width: 768px) {
      bottom: -40px;
    }
  }
}