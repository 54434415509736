aside.sidebar {
    --header-height: 40px;
    width: 100%;
    display: block;
    height: calc(100vh - var(--header-height));
    overflow: hidden;
    position: sticky;
    background-color: #efefef;
    top: var(--header-height);
    left: 0;
    transition: .3s;
    flex-basis: 44px;
    flex-shrink: 0;
    z-index: 200;
    @media only screen and (max-width: 576px) {
        position: absolute;
        top: 0;
        width: 0;
        left: -50px;
    }
    &.active{
        flex-basis: 240px;
        @media only screen and (max-width: 576px) {
            flex-basis: 100%;
            width: 100%;
            left: 0;
        }
        .navbar-nav{
            .nav-list{
                .nav-item{
                    .nav-link{
                        span{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .navbar-nav{
        .nav-list{
            padding-left: 0;
            padding-top: 10px;

            .nav-item{
                margin-bottom: 5px;
                .nav-link{
                    display: flex;
                    align-items: center;
                    background-color: #efefef;
                    transition: .3s;
                    padding-left: 10px;
                    padding-right: 10px;
                    overflow: hidden;
                    &.active{
                        background-color: #ccc;
                    }
                    span{
                        margin-left: 5px;
                        opacity: 0;
                        transition: .3s;
                    }
                }
            }
        }
    }
    
}