header.main-navbar{
    .dropdown-menu{
        left: unset;
        right: 0;
        min-width: 180px;
        a, button{
            text-decoration: none;
            display: block;
            color: rgb(33, 37, 41);
            width: 100%;
            text-align: left;
            background-color: transparent;
            padding: 5px 10px;
            border: 0;
            &:hover{
                background-color: #ccc;
            }
        }
    }
}