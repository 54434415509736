*,*::before,*::after{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

main, section{
    position: relative;
}

a{
    text-decoration: none;
}

p{
    margin-bottom: 0;
}