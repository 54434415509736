@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

table.MuiTable-root{
    thead{
        tr{
            th{
                &.MuiTableCell-alignLeft {
                    padding: 16px;
                }
            }
        }
    }
}

/***** Header CSS ******/
body {
    font-family: 'Montserrat', sans-serif;
}

header {
    width: 100%;
    z-index: 999;
    position: relative;
    top: 0;
    background: #239adc;

    .header  {
      padding: 0px 0px;
    }
  }
  
  .scrolledHeader {
    box-shadow: 0px 0px 30px #0000001A;
    background-color: #fff;
  }
  
  .navbar_two {
    background-color: transparent !important;
  }
  
  .navbar_one {
    background-color: #FFFFFF !important;
  }
  
  .navbar_two_nav {
    margin: auto;
  }

  .pull-right {
    float: right;
  }
  
  .navbar_two_nav li a.nav-link {
    color: #ffffffec !important;
    padding: 10px 15px !important;
    font-size: 16px !important;

    font-size: 18px;
    font-weight: 500;
    line-height: 28px;

    &.underline {
        text-decoration: underline;
    }

  }
  
  .navbar_two_nav li a.nav-link:hover {
    color: #fff !important;
  }
  
  li .login_button {
    color: #fff !important;
    padding: 0 25px !important;
    width: auto;
    height: 40px;
    line-height: 38px;
    margin-top: 6px;
    border-radius: 30px;
  }
  
  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
  }
  
  .navbar-expand-lg .navbar-collapse.nav_collapse {
    display: block !important;
  }
  
    .logo {
        align-items: flex-end;
        display: flex;
        width: 130px;
        img {
            width: 60%;
        }
        .favicon {
            width: 35%;
            margin-right: 8px;
        }
    }
  
  .navbar_collapse .logo img {
    display: none;
  }
  
  .navbar_collapse .row {
    width: 100%;
  }
  
  .navbar_two {
    padding: 5px 0px;
    align-items: center;
  }
  
  /***** Header CSS ******/
  /***** Slider CSS Start ******/
  .slider-sec {
    // background-image: linear-gradient(45deg, black, transparent), url('../../../assets/images/background/login-register.jpg');
    background-size: cover;
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:after {
        content: '';
        background: linear-gradient(45deg, #000000ac, #000000ac);
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
  }

  .slider-cont {
    text-align: center;
    z-index: 1;
    
    h2 {
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 20px;
        font-size: 64px;
        font-weight: 600;
        line-height: 48px;
    }
  
    p {
        color: #fff;
        font-weight: 500px;
        margin-bottom: 25px;
        font-size: 30px;
        line-height: 53px;
    }
  
    button {
        padding: 5px 25px;
      font-size: 20px;
      font-weight: 200px !important;
      background: #239adc;
      height: 69px;
      border: none;
    }
  }

  /***** Slider CSS End ******/
  /***** how-it-work CSS ******/
  .how-it-work-sec {
    padding: 50px 0px;
    background: #ffffff;
    .how-it-work-img {
      position: relative;
      text-align: right;
      .workImg {
        position: relative;
        width: 100%;
        z-index: 1;
      }
      .work-video {
        width: 100%;
        height: 100%;
        z-index: 1;
        position: relative;
        margin-bottom: -6px;
      }
      .play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        width: 50px;
      }
      &::after {
        content: '';
        position: absolute;
        width: 220px;
        height: 160px;
        background: #E4F5FF;
        top: -15px;
        right: -15px;
        z-index: 0;
      }
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #239ADC;
        top: 0px;
        right: 0px;
        z-index: 2;
        opacity: 0.2;
      }
    }
    .how-it-work-cont {
        position: relative;
        z-index: 1;
        margin-left: 60px;
        &::after {
            content: '';
            position: absolute;
            left: -40px;
            top: -20px;
            width: 85px;
            height: 85px;
            background: #DCEDFE;
            border-radius: 50%;
            z-index: -1;
            opacity: 0.4;
        }

      h3 {
        text-transform: uppercase;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        border: 1px solid #c5c5c5;
        color: #000000;
        padding: 0px 15px 0px;
        letter-spacing: 3px;
        line-height: 1.5;
      }
      h2 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 39px;
        margin-bottom: 20px;
        color: #1C1C1C;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        color: #777777;
      }
      button {
        height: 60px;
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1px;
        border-radius: 40px;
        background: #2C79FF;
        text-transform: capitalize;
      }
    }
  }
  /***** how-it-work CSS ******/
  /***** Feature CSS Start ******/
  .feature-sec {
    padding: 25px 20px 10px;
    background-size: contain;

    .head-sec {
      h2 {
        color: #1C1C1C;
        margin-bottom: 45px;
        font-size: 30px;
        font-weight: 600;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: center;

      }
    }
    .feature-card {
      background: #fff;
      border-radius: 3px;
      padding: 15px;
      text-align: center;
      padding: 15px;
      margin-top: 35px;
      margin-bottom: 25px;
      min-height: 550px;
  
      .img-sec {
        border-radius: 50%;
        border: 2px solid #ddd;
        background: #fff;
        width: 120px;
        margin: auto;
        margin-top: -75px;
        padding: 25px;
        
        img {
          width: 100%;
        }
      }
      h3 {
        color: #000;
        margin: 10px 0px 5px;
        font-size: 21px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.011em;
        text-align: center;
      }
    
      p {
        color: #777777;
        margin-bottom: 5px;

        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: -0.011em;
        text-align: center;
      }
    }
  }

  /***** Feature CSS End ******/
  /***** Doc haplo CSS start ******/
  .document-haplo-sec {
    padding: 60px 0px;
    .doc-haplo-card {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 3px;
      padding: 45px;
      text-align: center;

      h2 {
        color: #fff;
        margin-bottom: 35px;
        letter-spacing: 1px;

        font-size: 30px;
        font-weight: 600;
        line-height: 39px;
        text-align: center;

      }

      button {
        background: #D4EAFF;
        min-width: 100px;
        border: none;
        padding: 5px;
        height: 60px;
        width: 156px;

        img {
          width: 40px;
        }
      }
    }
  }
  /***** Doc haplo CSS End ******/
  /***** Speacial Care CSS Start ******/
  .special-care-sec {
    text-align: center;
    padding: 30px;
    
    h3 {
    position: relative;
      text-transform: uppercase;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      border: 1px solid #c5c5c5;
      color: #000;
      padding: 0px 15px;
      letter-spacing: 3px;
      line-height: 1.5;
      margin-bottom: 8px;
      &::after {
          content: '';
          position: absolute;
          right: -80px;
          top: 0px;
          width: 85px;
          height: 85px;
          background: #DCEDFE;
          border-radius: 50%;
          z-index: -1;
          opacity: 0.4;
      }
    }
    h2 {
        letter-spacing: 1px;
        color: #202430;
        margin: auto;
        margin-bottom: 20px;

        font-size: 30px;
        font-weight: 600;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: center;

    }
    p {
        color: #676767;
        line-height: normal;
        margin-bottom: 5px;

        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.019em;
        text-align: center;
    }
  }
  /***** Speacial Care CSS End ******/
  /***** Dashboard CSS Start ******/
  .dashboard-section {
    .card {
      border: none;
      border-radius: 12px;

      .card-body {
        .card-title {
          font-size: 3rem;
        }
      }
    }
    .bg-1 {
      background: #ffeee3;
      .card-subtitle {
        color: #ac8367 !important;
      }
    }
    .bg-2 {
      background: #edfbef;
      .card-subtitle {
        color: #518a59 !important;
      }
    }
    .bg-3 {
      background: #e6f5fa;
      .card-subtitle {
        color: #67909d !important;
      }
    }
    .bg-4 {
      background: #f4f5f8;
      .card-subtitle {
        color: #5c688d !important;
      }
    }
  }
  /***** Dashboard CSS End ******/
  /***** footer CSS Start ******/
  .footer {
    left: 0px;
    background: #eeeeee;
    border: none;
    padding: 10px;
    p {
      text-align: center;
      margin-bottom: 0;
      color: #5e5e5e;
      font-size: 16px;
      line-height: 32px;
    }
  }
  .footer {
    &.footer-nagative {
      margin: 0 -1rem -2rem -1rem;
      left: 0px;
      background: #eeeeee;
      border: none;
      padding: 10px;
      p {
        text-align: center;
        margin-bottom: 0;
        color: #5e5e5e;
        font-size: 16px;
        line-height: 32px;
      }
    }
  }
  /***** footer CSS End ******/


  @media (min-width:1400px) and (max-width:1920px) {
    .slider-sec {
      .slider-cont {
        h2 {
            font-size: 55px;
        }
      
        p {
            font-size: 20px;
            line-height: 40px;
        }
      
        button {
          font-size: 16px;
          height: 50px;
        }
      }
    }
  
    .how-it-work-sec {
      padding: 50px 0px;
      .how-it-work-cont {  
        h3 {
          font-size: 14px;
        }
        h2 {
          font-size: 26px;
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          width: 85%;
        }
      }
    }

    .feature-sec {
      padding: 25px 20px 10px;
      background-size: contain;
  
      .head-sec {
        h2 {
          color: #1C1C1C;
          margin-bottom: 45px;
          font-size: 30px;
          font-weight: 600;
          line-height: 39px;
          letter-spacing: 0em;
          text-align: center;
  
        }
      }
      .feature-card {
        h3 {
          font-size: 18px;
        }
      
        p {
          font-size: 13px;
          line-height: 22px;
        }
      }
    }

    .document-haplo-sec {
      .doc-haplo-card {
        h2 {
          font-size: 26px;
          margin-bottom: 25px;
        }
  
        button {
          min-width: 100px;
          height: 50px;
          width: 150px;
        }
      }
    }

    .special-care-sec {
      h2 {
        font-size: 28px;
        line-height: 35px;
      }
      p {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
  @media (min-width:1024px) and (max-width:1400px) {
    .slider-cont {
      h2 {
        font-size: 50px;
        margin-bottom: 25px;
      }
      p {
        font-size: 22px;
        line-height: 24px;
        margin-bottom: 25px;
      }
      button {
        font-size: 18px;
        height: 55px;
      }
    }
    .feature-sec {
        .feature-card {
            min-height: 430px;
        }
    }
  }

  @media (max-width:991px) {
    .navbar-toggler {
      border: 1px solid #fff;
      .navbar-toggler-icon {
        filter: brightness(1) invert(1);
      }
    }
    .navbar-collapse {
      &.collapse {
        margin-top: 15px;

        .navbar-nav {
          a {
            margin: 5px 0px;
          }
        }
      }
    }
  }

  @media (min-width:768px) and (max-width:1023px) {
    .slider-cont {
      h2 {
        font-size: 50px;
        margin-bottom: 10px;
      }
      p {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 15px;
      }
      button {
        font-size: 15px;
        height: 55px;
      }
    }
    .feature-sec {
        .feature-card {
            margin-top: 60px;
            min-height: 440px;
        }
    }
  }

  @media (max-width:767px) {
    .navbar_two_nav {
      float: left;
    }
    .slider-cont {
      padding: 10px;
      h2 {
        font-size: 32px;
        margin-bottom: 6px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 15px;
      }
      button {
        font-size: 15px;
        height: 55px;
      }
    }
    .how-it-work-sec {
      .how-it-work-cont {
        margin-left: 0;
        h2 {
          font-size: 24px;
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          line-height: 22px;
        }
      }
      .how-it-work-img {
        text-align: left;
        img {
          width: 100%;
        }
      }
    }
    .feature-sec {
      padding: 25px;
      .head-sec h2 {
        color: #1C1C1C;
        margin-bottom: 45px;
        font-size: 22px;
        line-height: 26px;
      }
      .feature-card {
        margin-top: 55px;
        min-height: auto;

        h3 {
          font-size: 16px;
        }

        p {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .document-haplo-sec {
      padding: 35px 0px;
      .doc-haplo-card {
        h2 {
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
        }
        button {
          height: 45px;
          width: 135px;
        }
      }
    }

    .special-care-sec {
      overflow: hidden;
      h2 {
        font-size: 22px;
        line-height: 25px;
      }

      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  @media (max-width:320px) {
    .navbar_two_nav {
      float: left;
    }
    .slider-cont {
      h2 {
        font-size: 28px;
      }
      p {
        font-size: 16px;
      }
      button {
        font-size: 15px;
        height: 55px;
      }
    }
  }